import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/pages/Footer.js/Footer';
import Main from './components/pages/Main/Main';
import CountdownTimer from './components/CountdownTimer';

function App() {
  return (
    <>
      <Navbar />
      <CountdownTimer CountdownTimestampMs={1669046424582} />
      <Main />
      <Footer />
    </>
  );
}

export default App;
