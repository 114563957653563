import React from 'react';
import './Main.css';
import { Button } from '../../Button';
import trtb from "../../../images/trtb.png";
import tca from "../../../images/tca.png";
import tcsb from "../../../images/tcsb.png";
import tcsc from "../../../images/tcsc.png";
import ttca from "../../../images/ttca.png";
import twheel from "../../../images/tca.png";

function Main() {
    return (
        <div className='footer-container'>
            <section className='footer-subscription'>
                <p className='footer-subscription-heading'>Sailing Dates: Monday November 21 - Sunday November 27</p>

                <p className='footer-subscription-text'>Group Number: R73WC1 </p>
                <p className='footer-subscription-text'>Group Name: Thanksgiving Cruise</p>
                <p className='footer-subscription-text'>Carnival Group Booking: 1-866-721-3225 Option 3 </p>
                <p className='footer-subscription-text'>Group Sales Hours: Mon - Fri, 9 AM - 8 PM EST.</p>
                <p className='footer-subscription-text'>Final Payment Due: August 23, 2022</p>

            </section>
            <div className='footer-links'>
                <div className='footer-link-wrapper'>
                    <div className='footer-link-items'>
                        <button className='btn3 btn--outline'><a target="_blank" href='https://www.carnival.com/Legal/covid-19-legal-notices/covid-19-guest-protocols?icid=advisory_cruisehealth_052521'>Vaccination Info</a></button>
                    </div>
                    <div className='footer-link-items'>
                        <button className='btn3 btn--outline'><a target="_blank" href='https://www.hilton.com/en/book/reservation/deeplink/?ctyhocn=MLBCVHX&groupCode=CHHGTC&arrivaldate=2022-11-20&departuredate=2022-11-21&cid=OM,WW,HILTONLINK,EN,DirectLink&fromId=HILTONLINKDIRECT'>Hotel Link</a></button>
                    </div>
                    <div className='footer-link-items'>
                        <button className='btn3 btn--outline'><a target='_blank' href='https://docs.google.com/forms/d/e/1FAIpQLSe5isjxYBBWFxz_NjmQRa_xvo-vRLI_rPjAiszXkwkBWcYPpw/viewform?usp=sf_link'>Gift Vote</a></button>
                    </div>
                    <div className='footer-link-items'>
                        <button className='btn3 btn--outline'><a target="_blank" href='https://www.carnival.com/itinerary/6-day-eastern-caribbean-cruise/pt-canaveral/freedom/6-days/ecd?abCode=&currency=USD&hideSailingEvents=true&itinportcode=pcv&locality=1&military=N&numGuests=2&offerCode=&pastGuest=Y&promoCode=&rateCode=&roomType=IS&sailDate=11212022&senior=N&smp=Y'>Cruise Itinerary</a></button>
                    </div>
                    <div className='footer-link-items'>
                        <button className='btn3 btn--outline'><a target="_blank" href='https://www.carnival.com/cruise-ships/carnival-freedom.aspx'>Carnival Freedom</a></button>
                    </div>
                </div>
                <div className='footer-link-wrapper'>


                </div>
                <div className='footer-link-wrapper'>
                   
                </div>
            </div>
            <div className='footer-subscription'>
                <p className='footer-subscription-heading'>Tshirt Logos</p>
                <p className='footer-subscription-text'>We have several logos available to make your own tshirts, </p><p className='footer-subscription-text'> mugs, tote bags, etc for the cruise. Download the logo design and </p><p className='footer-subscription-text'> upload it to <a target="_blank" href='https://www.vistaprint.com/clothing-bags/t-shirts/all-t-shirts'> Vista Print.</a> or a similar website to create your cruise swag.</p>
                <img className='img-fluid' src={trtb}></img>
                <img className='img-fluid' src={tca}></img>
                <img className='img-fluid' src={tcsb}></img>
                <img className='img-fluid' src={tcsc}></img>
                <img className='img-fluid' src={ttca}></img>
                <img className='img-fluid' src={twheel}></img>
                <p className='footer-subscription-text'> </p>
            </div>
        </div>
    );
}

export default Main;
